export enum Route {
    Home = 'home',
    About = 'about',
    WhatWeDo = 'what-we-do',
    Services = 'services',
    OpenSource = 'open-source',
    OpenSourceSingle = 'open-source-single',
    Contact = 'contact-us',
    WorkWithUs = 'work-with-us',
    CaseStudy = 'case-study',
    Careers = 'careers',
    CareersSingle = 'careers-single',
    Blog = 'blog',
    BlogPost = 'blog-post',
    NotFound = 'not-found',
}
