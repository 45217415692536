

    import { useStickyMenu } from '../compositions/UseStickyMenu'
    import { defineComponent } from 'vue'
    import { interactWithMenu } from '../compositions/menu/InteractWithMenu'
    import { useMobileMenu } from '../compositions/menu/UseMobileMenu'
    import { useLoopReferences } from '../compositions/UseLoopReferences'
    import { useTranslation } from '../compositions/UseTranslation'
    import { Locale } from '../enums/Locale'
    import { Route } from '../enums/Route'
    import Chinese from './Icons/Chinese.vue'
    import Hamburger from './Icons/Hamburger.vue'
    import DC from './Logos/DC.vue'
    import DCLogo from './Logos/DCLogo.vue'
    import SocialMediaButton from './SocialMediaButton.vue'
    // import Paragraph from './Paragraph.vue'

    export default defineComponent({
        name: 'Menu',
        components: {
            DC,
            DCLogo,
            Hamburger,
            Chinese,
            SocialMediaButton
        },
        setup() {

            const { modeClass } = interactWithMenu()
            const { setReference: setLinksReference } = useLoopReferences<HTMLUListElement>()
            const { show, toggle, isFluid } = useMobileMenu()

            const {
                menuStyle,
                menuRef,
                menuClasses,
                clearMenuBackground,
                hideMenu,
                showMenu,
                isMenuHidden
            } = useStickyMenu()

            const { menu, isCNLocale, switchLocale } = useTranslation()

            return {
                Route,
                Locale,
                modeClass,
                toggle,
                show,
                menuStyle,
                menuRef,
                menuClasses,
                data: menu,
                isFluid,
                isCNLocale,
                switchLocale,
                setLinksReference,
                clearMenuBackground,
                hideMenu,
                showMenu,
                isMenuHidden,
                socialMedia: [
                    {
                        name: 'LinkedIn',
                        color: '#4285EB',
                        link: 'https://linkedin.com/company/digital-creative-asia/'
                    },
                    {
                        name: 'Instagram',
                        color: '#F2994A',
                        link: 'https://instagram.com/dc.asia'
                    }
                ]
            }

        }
    })

