module.exports = {
    purge: {
        content: [
            './src/**/*.vue',
            './src/**/*.ts'
        ],
        options: {
            safelist: {
                standard: [ /^w-/, /^ml-/ ]
            }
        }
    },
    theme: {
        extend: {
            fontSize: {
                '2xs': [ '9px', '24px' ],
                xs: [ '10px', '28px' ],
                sm: [ '11px', '28px' ],
                base: [ '16px', '30px' ],
                lg: [ '18px', '28px' ],
                xl: [ '24px', '38px' ],
                '2xl-mo': [ '32px', '52px' ],
                '2xl': [ '42px', '66px' ],
                '3xl-mo': [ '36px', '150%' ],
                '3xl': [ '52px', '77px' ]
            },
            margin: theme => ({
                ...theme('width')
            }),
            cursor: {
                none: 'none'
            }
        },
        fontFamily: {
            'mabry-pro': [ 'Mabry Pro', 'Source Hans', 'sans-serif' ],
            body: [ 'GT America Mono', 'sans-serif' ]
        },
        colors: {
            blue: {
                100: '#F5FDFF',
                200: '#B3EBFF',
                300: '#9AE1FB',
                400: '#29CDFF',
                500: '#4285EB'
            },
            gray: {
                100: '#F3F3F1',
                200: '#CCCCCC',
                300: '#999999',
                400: '#666666',
                600: '#444444',
                900: '#333333'
            },
            red: {
                200: '#FE5D5E'
            },
            green: {
                200: '#2CA95E'
            },
            teal: {
                200: '#E2FFF8'
            },
            pink: {
                200: '#FFEDFD',
                300: '#FBA7F2',
                400: '#C000AF',
                500: '#F5EAFE'
            },
            yellow: {
                200: '#FFFFEA'
            },
            purple: {
                100: '#F9F2FF',
                200: '#E4D5F0'
            },
            FFEFD7: '#FFEFD7',
            FFF2F9: '#FFF2F9',
            E6E6E6: '#E6E6E6',
            transparent: 'transparent',
            white: '#ffffff',
            black: '#333333',
            orange: '#FF8A00',
            'pure-black': '#000000',
            secondary: '#3F86EA'
        },
        backgroundColor: theme => ({
            ...theme('colors'),
            gray: '#F3F3F1',
            'hublot-black': '#171717',
            'hublot-gray': '#3A3A3A'
        }),
        screens: {
            sm: { max: '767px' },
            md: { min: '768px' },
            lg: { min: '1024px' },
            xl: { min: '1440px' }
        }
    },
    variants: {},
    plugins: []
}
