import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { Route } from '../enums/Route'
import { Theme } from '../enums/Theme'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: Route.Home,
        component: () => import(/* WebpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            theme: Theme.Default
        }
    },
    {
        path: '/blog',
        name: Route.Blog,
        component: () => import(/* WebpackChunkName: "blog" */ '../views/Blog.vue'),
        meta: {
            theme: Theme.Default
        }
    },
    {
        path: '/blog/:slug',
        name: Route.BlogPost,
        component: () => import(/* WebpackChunkName: "blog-post" */ '../views/BlogPost.vue'),
        meta: {
            theme: Theme.Default
        }
    },
    {
        path: '/cases/:slug',
        name: Route.CaseStudy,
        component: () => import(/* WebpackChunkName: "case-study" */ '../views/CaseStudy.vue'),
        meta: {
            theme: Theme.Default
        }
    },
    {
        path: '/open-source',
        name: Route.OpenSource,
        component: () => import(/* WebpackChunkName: "open-source" */ '../views/OpenSource.vue'),
        meta: {
            theme: Theme.Blue
        }
    },
    {
        path: '/open-source/:slug',
        name: Route.OpenSourceSingle,
        component: () => import(/* WebpackChunkName: "open-source" */ '../views/OpenSourceSingle.vue'),
        meta: {
            theme: Theme.Default
        }
    },
    {
        path: '/what-we-do/',
        name: Route.WhatWeDo,
        component: () => import(/* WebpackChunkName: "what-we-do" */ '../views/WhatWeDo.vue'),
        meta: {
            theme: Theme.LightPink
        }
    },
    {
        path: '/services/:slug',
        name: Route.Services,
        component: () => import(/* WebpackChunkName: "laravel" */ '../views/Services.vue'),
        meta: {
            theme: Theme.Default
        }
    },
    // {
    //     path: '/careers',
    //     name: Route.Careers,
    //     component: () => import(/* WebpackChunkName: "careers" */ '../views/Careers.vue'),
    //     meta: {
    //         theme: Theme.Blue
    //     }
    // },
    // {
    //     path: '/careers/:slug',
    //     name: Route.CareersSingle,
    //     component: () => import(/* WebpackChunkName: "careers-single" */ '../views/CareersSingle.vue'),
    //     meta: {
    //         theme: Theme.Default
    //     }
    // },
    {
        path: '/about',
        name: Route.About,
        component: () => import(/* WebpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            theme: Theme.Green
        }
    },
    {
        path: '/contact-us',
        name: Route.Contact,
        component: () => import(/* WebpackChunkName: "contact" */ '../views/Contact.vue'),
        meta: {
            theme: Theme.Pink
        }
    },
    {
        path: '/work-with-us',
        name: Route.WorkWithUs,
        component: () => import(/* WebpackChunkName: "work-with-us" */ '../views/WorkWithUs.vue'),
        meta: {
            theme: Theme.Purple
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: Route.NotFound,
        component: () => import(/* WebpackChunkName: "not-found" */ '../views/NotFound.vue')
    }
]

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '--active',
    routes,
    scrollBehavior() {

        return { top: 0 }

    }
})

if (process.env.NODE_ENV === 'production' && window.__HEADLESS === undefined) {

    router.afterEach(to => {

        gtag('config', 'UA-1234567-89', { page_path: to.fullPath })

    })

}
