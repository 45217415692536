let previousPageName = ''

export function checkIsSeamlessPageTransition(to: any, from: any, record = false) {

    const isSeamlessPageTransition = to.params.isSeamless === 'true' ||
        (from.params.isSeamless === 'true' &&
            to.name === previousPageName)

    if (record) {

        previousPageName = from.name

    }

    return isSeamlessPageTransition

}
