import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_Column, { span: [ 2, 3 ] }, {
        default: _withCtx(() => [
          _createVNode(_component_AOS, {
            type: "fade-up",
            duration: "800",
            delay: "1000"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Heading, {
                tag: "h2",
                innerHTML: _ctx.heading
              }, null, 8, ["innerHTML"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_AOS, {
        type: "fade-up",
        duration: "800",
        delay: "1000"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, { span: [ 2, 3 ] }, {
            default: _withCtx(() => [
              _createVNode(_component_Button, {
                route: _ctx.route,
                class: "contact-header__button sm:mt-12"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
              }, 8, ["route"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}