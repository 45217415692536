import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "ml-2 inline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component.is), _mergeProps({
    class: ["anchor", { '--underlined': _ctx.underlined, 'font-bold': _ctx.bold }]
  }, _ctx.component.params), {
    default: _withCtx(() => [
      (_ctx.underlined)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _renderSlot(_ctx.$slots, "default", { key: 1 }),
      (_ctx.showArrow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " → "))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["class"]))
}