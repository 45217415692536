import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Locale } from '../enums/Locale'

/**
 * NEW
 */
import homeEn from '../cms/en/pages/home.json'
import homeCn from '../cms/cn/pages/home.json'
import aboutEn from '../cms/en/pages/about.json'
import aboutCn from '../cms/cn/pages/about.json'
import blogEn from '../cms/en/pages/blog.json'
import blogCn from '../cms/cn/pages/blog.json'
import careersEn from '../cms/en/pages/careers.json'
import careersCn from '../cms/cn/pages/careers.json'
import openSourceEn from '../cms/en/pages/open-source.json'
import openSourceCn from '../cms/cn/pages/open-source.json'
import whatWeDoEn from '../cms/en/pages/what-we-do.json'
import whatWeDoCn from '../cms/cn/pages/what-we-do.json'
import contactEn from '../cms/en/pages/contact-us.json'
import contactCn from '../cms/cn/pages/contact-us.json'
import blogsEn from '../cms/en/blogs.json'
import blogsCn from '../cms/cn/blogs.json'
import contactFormEn from '../cms/en/forms/contact_us.json'
import contactFormCn from '../cms/cn/forms/contact_us_cn.json'
import agileTeamsEn from '../cms/en/globals/agile_teams.json'
import agileTeamsCn from '../cms/cn/globals/agile_teams.json'
import footerEn from '../cms/en/globals/footer.json'
import footerCn from '../cms/cn/globals/footer.json'

import workEn from '../data/en/work.json'
import workCn from '../data/cn/work.json'

import menuEn from '../data/en/menu.json'
import menuCn from '../data/cn/menu.json'

import cursorEn from '../data/en/cursor.json'
import cursorCn from '../data/cn/cursor.json'
import caseStudiesCn from '../data/cn/cases-studies.json'
import caseStudiesEn from '../data/en/cases-studies.json'
import notFoundEn from '../data/en/not-found.json'
import notFoundCn from '../data/cn/not-found.json'

const isCNLocale = ref(process.env.VUE_APP_LANGUAGE === Locale.CHINESE)

export function useCms() {

    const route = useRoute()

    function switchLocale() {

        if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_LANGUAGE) {

            const { path } = route

            window.location.href = isCNLocale.value ? `https://digitalcreative.cn${ path }` : `https://zh.digitalcreative.cn${ path }`

        } else {

            isCNLocale.value = !isCNLocale.value

        }

    }

    return {
        isCNLocale,
        switchLocale,
        currentLocale: computed(() => isCNLocale.value ? Locale.CHINESE : Locale.ENGLISH),

        /**
         * CMS - pages
         */
        home: computed(() => (isCNLocale.value ? homeCn : homeEn)),
        blogs: computed(() => isCNLocale.value ? blogsCn : blogsEn),
        careers: computed(() => isCNLocale.value ? careersCn : careersEn),
        blog: computed(() => isCNLocale.value ? blogCn : blogEn),
        openSource: computed(() => isCNLocale.value ? openSourceCn : openSourceEn),
        whatWeDo: computed(() => (isCNLocale.value ? whatWeDoCn : whatWeDoEn)),
        newContact: computed(() => isCNLocale.value ? contactCn : contactEn),

        /**
         * CMS - forms
         */

        contactForm: computed(() => (isCNLocale.value ? contactFormCn : contactFormEn)),

        /**
         * CMS - globals
         */
        footer: computed(() => isCNLocale.value ? footerCn : footerEn),
        agileTeams: computed(() => isCNLocale.value ? agileTeamsCn : agileTeamsEn),

        /**
         * Old
         */
        about: computed(() => (isCNLocale.value ? aboutCn : aboutEn)),
        work: computed(() => (isCNLocale.value ? workCn : workEn)),
        contact: computed(() => (isCNLocale.value ? contactCn : contactEn)),
        menu: computed(() => (isCNLocale.value ? menuCn : menuEn)),
        // Footer: computed(() => (isCNLocale.value ? footerCn : footerEn)),
        cursor: computed(() => (isCNLocale.value ? cursorCn : cursorEn)),
        caseStudies: computed(() => (isCNLocale.value ? caseStudiesCn : caseStudiesEn)),
        notFound: computed(() => (isCNLocale.value ? notFoundCn : notFoundEn))
    }

}
