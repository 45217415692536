import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Transition as _Transition, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-body" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Debugger = _resolveComponent("Debugger")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Debugger),
    _createVNode(_component_AOS, {
      type: "fade-down",
      duration: "1500",
      "persistent-attributes": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Menu, {
          ref: "menu",
          class: "app__menu"
        }, null, 512)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_RouterView)
    ]),
    _createVNode(_Transition, {
      "enter-from-class": "--enter-from",
      "enter-to-class": "--enter-to",
      "leave-active-class": "--leave-active",
      onEnter: _ctx.duringCurtainEntering,
      onAfterEnter: _ctx.afterCurtainEnter
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: "page-curtain",
          style: _normalizeStyle({ background: _ctx.curtainColor })
        }, null, 4), [
          [_vShow, _ctx.isPageCurtainShowing]
        ])
      ]),
      _: 1
    }, 8, ["onEnter", "onAfterEnter"]),
    _withDirectives(_createVNode(_component_Footer, null, null, 512), [
      [_vShow, _ctx.hasRouterViewMounted]
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "head" }, [
      (!_ctx.isBrowserMode)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.currentLocale === _ctx.Locale.CHINESE)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("link", {
                    rel: "preload",
                    href: require('./scss/fonts/source-hans/SourceHanSansSC-Bold-subset.woff2'),
                    as: "font",
                    type: "font/woff2",
                    crossorigin: ""
                  }, null, 8, _hoisted_2),
                  _createElementVNode("link", {
                    rel: "preload",
                    href: require('./scss/fonts/source-hans/SourceHanSansSC-Bold-subset.woff'),
                    as: "font",
                    type: "font/woff",
                    crossorigin: ""
                  }, null, 8, _hoisted_3)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("link", {
                    rel: "preload",
                    href: require('./scss/fonts/mabry-pro/MabryPro-Bold.woff2'),
                    as: "font",
                    type: "font/woff2",
                    crossorigin: ""
                  }, null, 8, _hoisted_4),
                  _createElementVNode("link", {
                    rel: "preload",
                    href: require('./scss/fonts/mabry-pro/MabryPro-Bold.woff'),
                    as: "font",
                    type: "font/woff",
                    crossorigin: ""
                  }, null, 8, _hoisted_5),
                  _createElementVNode("link", {
                    rel: "preload",
                    href: require('./scss/fonts/gt-america-mono/GTAmericaMono-Regular.woff2'),
                    as: "font",
                    type: "font/woff2",
                    crossorigin: ""
                  }, null, 8, _hoisted_6),
                  _createElementVNode("link", {
                    rel: "preload",
                    href: require('./scss/fonts/gt-america-mono/GTAmericaMono-Regular.woff'),
                    as: "font",
                    type: "font/woff",
                    crossorigin: ""
                  }, null, 8, _hoisted_7),
                  _createElementVNode("link", {
                    rel: "preload",
                    href: require('./scss/fonts/gt-america-mono/GTAmericaMono-Medium.woff2'),
                    as: "font",
                    type: "font/woff2",
                    crossorigin: ""
                  }, null, 8, _hoisted_8),
                  _createElementVNode("link", {
                    rel: "preload",
                    href: require('./scss/fonts/gt-america-mono/GTAmericaMono-Medium.woff'),
                    as: "font",
                    type: "font/woff",
                    crossorigin: ""
                  }, null, 8, _hoisted_9)
                ], 64))
          ], 64))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}