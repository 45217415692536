import { MediaType } from '../enums/MediaType'

export function cleanEmptyProperty<T>(object: T): T {

    const result: any = {}

    for (const [ key, value ] of Object.entries(object)) {

        if (value !== null) {

            result[key] = value

        }

    }

    return result

}

export const isBrowserMode = !window.__HEADLESS

export function generateId(): string {

    return Math.random().toString(36).substr(2, 6)

}

export function isVideoType(type: MediaType): boolean {

    return [ MediaType.MP4 ].includes(type)

}

export function isImageType(type: MediaType): boolean {

    return [ MediaType.JPEG, MediaType.PNG, MediaType.WEBP ].includes(type)

}
