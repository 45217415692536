import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "relative z-10 flex menu__dc" }
const _hoisted_2 = { class: "flex items-center justify-start" }
const _hoisted_3 = { class: "md:w-full h-full sm:space-y-3 flex flex-col lg:flex-row sm:justify-center md:justify-center items-center lg:justify-end" }
const _hoisted_4 = { class: "menu__locale__inner h-11 w-11 flex items-center justify-center" }
const _hoisted_5 = {
  key: 1,
  class: "font-body text-sm bold"
}
const _hoisted_6 = { class: "lg:hidden flex justify-center items-center w-full space-x-5 pb-10" }
const _hoisted_7 = { class: "flex items-center space-x-3 lg:hidden justify-end z-10 relative cursor-pointer select-none" }
const _hoisted_8 = {
  key: 1,
  class: "text-sm bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DCLogo = _resolveComponent("DCLogo")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_DC = _resolveComponent("DC")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Chinese = _resolveComponent("Chinese")!
  const _component_SocialMediaButton = _resolveComponent("SocialMediaButton")!
  const _component_Hamburger = _resolveComponent("Hamburger")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "menuRef",
    role: "navigation",
    class: _normalizeClass(["menu flex items-center px-4 lg:px-0 justify-between", [ _ctx.modeClass, { '--show-menu': _ctx.show, '--hidden': _ctx.isMenuHidden }, _ctx.menuClasses ]]),
    style: _normalizeStyle(_ctx.menuStyle)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RouterLink, {
          to: { name: _ctx.Route.Home },
          "aria-label": "Home",
          class: "menu__new-logo"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DCLogo, { class: "block" })
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_RouterLink, {
          to: { name: _ctx.Route.Home },
          "aria-label": "Home",
          class: "lg:hidden"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DC, { class: "menu__dc__logo block" })
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["menu__items h-full items-center hidden lg:flex lg:justify-end", { '--show': _ctx.show }])
    }, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.items, (item, key) => {
          return (_openBlock(), _createElementBlock("li", {
            key: key,
            class: _normalizeClass(["menu__items__link", {
                        '--contact': item.route.name == _ctx.Route.Contact,
                        'lg:hidden': item.route.name === _ctx.Route.Home
                    }])
          }, [
            _createVNode(_component_AOS, {
              type: "fade-up",
              delay: key * 50,
              "is-group": "",
              "anchor-placement": "top-bottom"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RouterLink, {
                  to: { name: item.route.name }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 2
            }, 1032, ["delay"])
          ], 2))
        }), 128)),
        _createElementVNode("li", {
          class: "menu__locale",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchLocale && _ctx.switchLocale(...args)))
        }, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.isCNLocale)
              ? (_openBlock(), _createBlock(_component_Chinese, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, " ENG "))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialMedia, (social, index) => {
          return (_openBlock(), _createBlock(_component_SocialMediaButton, _mergeProps({ key: index }, social), null, 16))
        }), 128))
      ])
    ], 2),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", {
        class: "menu__locale --small",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.switchLocale && _ctx.switchLocale(...args)))
      }, [
        (!_ctx.isCNLocale)
          ? (_openBlock(), _createBlock(_component_Chinese, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, " ENG "))
      ]),
      _createElementVNode("div", {
        class: "menu__hamburger",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggle(!_ctx.show)))
      }, [
        _createVNode(_component_Hamburger, { "is-open": _ctx.show }, null, 8, ["is-open"])
      ])
    ])
  ], 6))
}