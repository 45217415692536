

    import { computed, defineComponent, PropType } from 'vue'
    import { RouteLocation } from 'vue-router'
    import { useTranslation } from '../compositions/UseTranslation'
    import { Locale } from '../enums/Locale'

    export default defineComponent({
        name: 'Anchor',
        props: {
            route: { type: [ Object, String ] as PropType<RouteLocation | string>, required: true },
            underlined: { type: Boolean, default: false },
            mailTo: { type: Boolean, default: false },
            telephone: { type: Boolean, default: false },
            external: { type: Boolean, default: false },
            showArrow: { type: Boolean, default: false },
            bold: { type: Boolean, default: true },
            purple: { type: Boolean, default: false }
        },
        setup(props) {

            const { isCNLocale } = useTranslation()

            const routeParameters = computed(() => {

                const name = props.route.name
                const parameters = props.route.params
                const locale = isCNLocale ? Locale.CHINESE : ''
                const routeName = name || props.route

                return {
                    name: routeName,
                    params: { locale, ...parameters }
                }

            })

            const externalHref = computed(() => {

                if (props.mailTo) {

                    return `mailto:${ props.route }`

                }

                if (props.telephone) {

                    return `tel:${ props.route }`

                }

                return props.route

            })

            return {

                component: computed(() => {

                    if (!props.mailTo && !props.telephone && !props.external) {

                        return {
                            is: 'router-link',
                            params: {
                                to: routeParameters.value
                            }
                        }

                    }

                    return {
                        is: 'a',
                        params: {
                            href: externalHref.value,
                            target: '_blank',
                            rel: 'noopener'
                        }
                    }

                })

            }

        }
    })

