

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'Paragraph',
        props: {
            heading: { type: Boolean, default: false },
            noMargin: { type: Boolean, default: false },
            innerSpace: { type: Boolean, default: true }
        }
    })

