import { defineComponent, onUnmounted, ref, watchEffect, onMounted } from 'vue'
import { currentActiveBreakPoint } from './UseResponsiveValue'
import { Breakpoint } from '../enums/Breakpoint'
import { useTheme } from './UseTheme'

export const isScrollingUp = ref(false)

export const isScrollingDown = ref(false)

export function useStickyMenu() {

    const { currentColor } = useTheme()
    const offsetTop = ref([ Breakpoint.SM, Breakpoint.MD ].includes(currentActiveBreakPoint.value) ? 0 : 40)
    const menuStyle = ref<Partial<CSSStyleDeclaration>>({
        marginTop: `${ offsetTop.value }px`
    })
    const menuReference = ref<HTMLElement | ReturnType<typeof defineComponent>>()
    const contactReference = ref<HTMLElement | ReturnType<typeof defineComponent>>()
    const menuClasses = ref([])
    const isMenuHidden = ref(false)
    const invisible = 'rgba(0, 0, 0, 0)'
    const previousScrollPosition = ref(0)

    function onScroll() {

        if (menuReference.value === undefined) {

            throw Error('Invalid element. Please set menu element reference correctly.')

        }

        let menuElement: HTMLElement

        if (menuReference.value instanceof HTMLElement) {

            menuElement = menuReference.value

        } else {

            menuElement = menuReference.value.$el ?? menuReference.value

        }

        if (window.scrollY < previousScrollPosition.value) {

            isScrollingUp.value = true
            isScrollingDown.value = false

        }

        if (window.scrollY > previousScrollPosition.value) {

            isScrollingDown.value = true
            isScrollingUp.value = false

        }

        if (window.scrollY === 0) {

            isScrollingDown.value = false
            isScrollingUp.value = true

        }

        previousScrollPosition.value = window.scrollY

        if (isScrollingDown.value === true) {

            menuClasses.value = []

            if (window.pageYOffset >= offsetTop.value) {

                menuStyle.value.marginTop = '0'

            }

            if (window.pageYOffset >= (offsetTop.value * 2)) {

                menuClasses.value.push('--scrolling-down')
                menuStyle.value.backgroundColor = invisible

            }

        }

        if (isScrollingUp.value === true) {

            menuClasses.value = []
            menuStyle.value.opacity = '1'
            menuStyle.value.backgroundColor = currentColor.value
            menuClasses.value.push('--scrolling-up')

            if (window.pageYOffset <= (offsetTop.value * 2)) {

                menuStyle.value.marginTop = `${ offsetTop.value }px`
                clearMenuBackground()

            }

        }

    }

    function removeOrAddTopMargin() {

        if ([ Breakpoint.SM, Breakpoint.MD ].includes(currentActiveBreakPoint.value)) {

            offsetTop.value = 0
            menuStyle.value.marginTop = '0'

        } else {

            offsetTop.value = 40

            if (window.pageYOffset <= (offsetTop.value * 2)) {

                menuStyle.value.marginTop = `${ offsetTop.value }px`
                clearMenuBackground()

            }

        }

    }

    function clearMenuBackground() {

        menuClasses.value = []
        menuStyle.value.backgroundColor = invisible

    }

    function hideMenu() {

        isMenuHidden.value = true

    }

    function showMenu() {

        isMenuHidden.value = false

    }

    onMounted(() => {

        window.addEventListener('scroll', onScroll, false)
        window.addEventListener('resize', removeOrAddTopMargin)

    })

    onUnmounted(() => {

        window.removeEventListener('scroll', onScroll, false)
        window.removeEventListener('resize', removeOrAddTopMargin)

    })

    return {
        menuStyle,
        menuRef: menuReference,
        contactRef: contactReference,
        menuClasses,
        clearMenuBackground,
        hideMenu,
        showMenu,
        isMenuHidden
    }

}
